import { AuthGuard } from './helpers/auth.guard';
import { DashboardComponent } from './examples/dashboard/dashboard.component';
import { NewsignupComponent } from './examples/newsignup/newsignup.component';
import { NgModule } from '@angular/core';
import { CommonModule, } from '@angular/common';
import { BrowserModule  } from '@angular/platform-browser';
import { Routes, RouterModule } from '@angular/router';

import { ComponentsComponent } from './components/components.component';
import { ProfileComponent } from './examples/profile/profile.component';
import { SignupComponent } from './examples/signup/signup.component';
import { LandingComponent } from './examples/landing/landing.component';
import { NucleoiconsComponent } from './components/nucleoicons/nucleoicons.component';
import { LandingPageComponent } from './landing-page/landing-page.component';
import { PricingComponent } from './pricing/pricing.component';
import { FormDetailsComponent } from './examples/form-details/form-details.component';
import { CheckoutComponent } from './examples/checkout/checkout.component';
import { UpcomingEventsComponent } from './examples/upcoming-events/upcoming-events.component';
import { PaymentSuccessComponent } from './examples/payment-success/payment-success.component';
import { PaymentFailComponent } from './examples/payment-fail/payment-fail.component';

const routes: Routes =[
    { path: '', redirectTo: 'home', pathMatch: 'full' },
    { path: 'home',             component: LandingPageComponent },
    { path: 'upcoming-events',  component: UpcomingEventsComponent},
    { path: 'user-profile',     component: ProfileComponent },
    { path: 'signin',           component: DashboardComponent },
    { path: 'signup',           component: NewsignupComponent },
    { path: 'landing',          component: LandingComponent },
    { path: 'nucleoicons',      component: NucleoiconsComponent },
    { path: 'landingPage',      component: ComponentsComponent },
    { path: 'boats',            component: PricingComponent },
    { path: 'details',          component: FormDetailsComponent },
    { path: 'checkout/:id',          component: CheckoutComponent ,},
    { path: 'payment-success/:id',          component: PaymentSuccessComponent },  
    { path: 'payment-fail',          component: PaymentFailComponent},  
    { 
      path: 'admin/dashboard', component: DashboardComponent 
    }
    
];

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    RouterModule.forRoot(routes,{
      useHash: false
    })
  ],
  exports: [
  ],
})
export class AppRoutingModule { }
