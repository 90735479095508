import { Component, OnInit } from "@angular/core";
import { AlertService } from "app/services/alert.service";
import { DataService } from "app/services/data.service";
import { Router, ActivatedRoute } from "@angular/router";
import {
  FormBuilder,
  FormGroup,
  Validators,
  ReactiveFormsModule,
} from "@angular/forms";
import { first } from "rxjs/operators";
import { CommonApisRouteService } from 'app/services/common-apis-route.service';

@Component({
  selector: 'app-newsignup',
  templateUrl: './newsignup.component.html',
  styleUrls: ['./newsignup.component.scss']
})
export class NewsignupComponent implements OnInit {

  form: FormGroup;
  show: boolean = false;

  constructor(
    private accountService: DataService,
    private alertService: AlertService,
    private router: Router,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private apiService : CommonApisRouteService
  ) {}

  ngOnInit() {
    this.form = this.formBuilder.group({
      firstname: ["", Validators.required],
      lastname: ["", Validators.required],
      email: ["", Validators.required],
      password: ["", Validators.required],
    });
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.form.controls;
  }

  // click event function toggle
  password() {
    this.show = !this.show;
  }

  onSubmit() {

    if (this.form.valid) {

        this.apiService.Register(this.form.value).then(t => {
           this.router.navigate(['/signin']);
        })
    }else{
      this.form.markAllAsTouched()
    }

  
  }

}
