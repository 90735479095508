import { Injectable } from '@angular/core';
import { CommonApiService } from './common-api.service';
import { AlertService } from './alert.service';

@Injectable()
export class CommonApisRouteService {
  event_id: any;
  private _event_id: any;
  public get getSuppliersid(): any {
    return this._event_id;
  }
  public setSuppliersid(id: any) {
    this._event_id = id;
  }

  constructor(private commonApiService: CommonApiService, private AlertService: AlertService) { }
  /**
   * Calls currencies listing api
   * @param params {Page, PageSize}
   */

   Register(params: any) {
    return new Promise((resolve, reject) => {
      this.commonApiService.getPromiseResponse({ apiName: '/register', methodType: 'post', parameterObject: params }).then(result => {
        resolve(result);
      }).catch(error => {
        if (error.message) {
          this.AlertService.error("", error.message)
        }
        reject(error);
      })
    })
  }
}
