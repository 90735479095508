import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule } from '@angular/router';
import { AppRoutingModule } from './app.routing';

import { AppComponent } from './app.component';
import { NavbarComponent } from './shared/navbar/navbar.component';
import { FooterComponent } from './shared/footer/footer.component';

import { ComponentsModule } from './components/components.module';
import { ExamplesModule } from './examples/examples.module';
import { LandingPageComponent } from './landing-page/landing-page.component';
import {TranslateModule, TranslateLoader} from '@ngx-translate/core'; //translate module for the several languages
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {HttpClientModule, HttpClient} from '@angular/common/http';
import { PricingComponent } from './pricing/pricing.component';
import { DataService } from './services/data.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CommonApiService } from './services/common-api.service';
import { CommonApisRouteService } from './services/common-apis-route.service';
import { AuthService } from './services/auth.service';
import { LoaderComponent } from './examples/loader/loader.component';
// import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { FormDetailsComponent } from './examples/form-details/form-details.component';
import { CheckoutComponent } from './examples/checkout/checkout.component';
import { CommonModule } from '@angular/common';
import { NouisliderModule } from 'ng2-nouislider';
import { JwBootstrapSwitchNg2Module } from 'jw-bootstrap-switch-ng2';
import { UpcomingEventsComponent } from './examples/upcoming-events/upcoming-events.component';



export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient, '/assets/i18n/','.json');
}


function appInitializer(authService: AuthService) {
  return () => {
    return new Promise((resolve) => {
      authService.getUserByToken().subscribe().add(resolve);
    });
  };
}


@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    FooterComponent,
    LandingPageComponent,
    PricingComponent,
    LoaderComponent,
    CheckoutComponent,
    UpcomingEventsComponent
  ],
  imports: [
  
    BrowserModule,        
    NgbModule,
    FormsModule,
    RouterModule,
    ComponentsModule,
    ExamplesModule,
    AppRoutingModule,
    HttpClientModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    CommonModule,    
    //FormsModule,
    //NgbModule,
    NouisliderModule,
    //RouterModule,
    JwBootstrapSwitchNg2Module,
    NgxDaterangepickerMd.forRoot(),
    TranslateModule.forRoot({
      loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient]
      }
  }),
  ],
  providers: [ {
    provide: APP_INITIALIZER,
    useFactory: appInitializer,
    multi: true,
    deps: [AuthService],
  },
    DataService,CommonApiService,CommonApisRouteService],
  bootstrap: [AppComponent]
})
export class AppModule { }
