import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { CalendarEvent, CalendarView } from 'angular-calendar';
import { CommonApiService } from 'app/services/common-api.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DashboardComponent implements OnInit {
  // selected: {startDate: Dayjs, endDate: Dayjs};

  view: CalendarView = CalendarView.Month;
  viewDate: Date = new Date();
  events: CalendarEvent[] =[];
  selectedDay: any;
  PrevEvent: any;
  showComponent: boolean;

  constructor(private route: Router, private commonApi: CommonApiService,private detectChange : ChangeDetectorRef) { }

  // public selectedMoment = new Date();

  // isInvalidDate(date:any) {
  //   return date.getDay() === 0;
  // }

  ngOnInit(): void {
    this.commonApi.getPromiseResponse({ apiName: '/Booking/', methodType: 'get', parameterObject: "" }).then((t) => {
      
      if (t.length > 0) {
        t.forEach(element => {
          this.events.push({
            start: new Date(new Date(element.bookingDate).toDateString()),
            end: new Date(new Date(element.bookingDate).toDateString()),
            title: 'NA'
          })
        });
      }
      this.showComponent = true;
      this.detectChange.detectChanges();
    }).catch((t) => {
      if (t?.message) {
        alert("Something went wrong! Please try again");
      }
    })
  }

  isDateSelected(e) {
    var returnData = false;
    this.events.forEach(element => {
      if (new Date(e.date).toDateString() == new Date(element.start).toDateString()) {
        returnData = true;
      }

    });
    return returnData;
  }

  GetDate(data) {    
    var isDateSelected = this.isDateSelected(data.day);
    if (isDateSelected) {
      
    } else {
      if (this.PrevEvent?.day != data.day) {

        let items = document.getElementsByClassName("selected-cell");
        
        for (let index = 0; index < items.length; index++) {
          const element = items[index];
          element.classList.remove("selected-cell")
          
        }

        data.sourceEvent.currentTarget.classList.add("selected-cell");
      
        this.PrevEvent = data;
        this.selectedDay = new Date(data.day.date).toDateString()
        

      }
      
    }
  }

  redirectoNextPage() {
    this.route.navigate(["details"], { queryParams: { date: this.selectedDay } })
  }

  redirectoBookingsPage(){
    this.route.navigate(["upcoming-events"])
  }

}
