import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Checkout } from 'app/models/checkout';
import { CommonApiService } from 'app/services/common-api.service';
import { environment } from 'environments/environment';
import { textChangeRangeIsUnchanged } from 'typescript';
import * as constants from '../../constants/constants.json';
import { LocalStorageService } from 'app/services/local-storage.service';




@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.scss']
})
export class CheckoutComponent implements OnInit {
  constantValues: any;
  bookingId: string;
  dayOfWeek: number;
  price: any;
  bookinfDetails: any;
  showComponent: boolean;
  addons: any[] = [];
  Ds_MerchantParameters: { DS_MERCHANT_AMOUNT: string; DS_MERCHANT_CURRENCY: string; DS_MERCHANT_MERCHANTCODE: string; DS_MERCHANT_MERCHANTURL: string; DS_MERCHANT_ORDER: string; DS_MERCHANT_TERMINAL: string; DS_MERCHANT_TRANSACTIONTYPE: string; DS_MERCHANT_URLKO: string; DS_MERCHANT_URLOK: string; };
  base64MerchantParams: string;
  paymentHandler: any;
  hideAddons: boolean;

  constructor(private activatedRoute : ActivatedRoute,private commonApi:CommonApiService, private route: Router,public translate: TranslateService,private localStorageService: LocalStorageService) {
    this.activatedRoute.params.subscribe(q=>{
      this.bookingId = window.atob(decodeURIComponent(q['id']));      
    })

    this.constantValues = constants;
   }

  ngOnInit(): void {
    this.invokeStripe();

    this.addons = this.constantValues.default.Addons;
    this.hideAddons = false;        
    this.addons = this.addons.sort(function(a, b){return a.index - b.index});   

    this.commonApi.getPromiseResponse({apiName: '/Booking/'+this.bookingId, methodType: 'get' }).then((t)=>{      
      
      this.bookinfDetails = t;
      this.dayOfWeek = new Date(t.bookingDate).getDay();
      switch(this.dayOfWeek){
        case 0:
        case 6:{
          this.price = this.constantValues.default.Prices.WeekendPrice;
        }
        default:{
          this.price = this.constantValues.default.Prices.WeekDayPrice;
        }
      }

      this.showComponent = true;
     }).catch((t)=>{
       if(t?.message)
       {
         alert("Something went wrong! Please try again");
       }
     })
  }
 
  calculatedTotal(): number{
    var total = this.price;

    this.addons.forEach(element => {
      if(element.IsSelected){total+= element.AddonPrice}
    });
    return total;
  }



  // payNow(e:any){
  //   var payData= [];
  //   this.addons.forEach(element => {
  //     if(element.IsSelected){
  //       var data = new Checkout();
  //       data.addonId = element.index;
  //       data.addonPrice = element.AddonPrice;
  //       payData.push(data);
  //     }
  //   });

  //   var bookingData = new Checkout();
  //   bookingData.addonId = 7;
  //   bookingData.addonPrice = this.price;
  //   payData.push(bookingData);

    
  //   this.commonApi.getPromiseResponse({apiName: '/Booking/AddAddons/'+this.bookingId, methodType: 'post',parameterObject: payData }).then((t)=>{      
  //     //this.route.navigate(["admin","dashboard"]);
  //     //this.form.submit();
  //    }).catch((t)=>{
  //      if(t?.message)
  //      {
  //        alert("Something went wrong! Please try again");
  //      }
  //    })

  // }

  payNow() {
    const paymentHandler = (<any>window).StripeCheckout.configure({
      key: environment.stripeKey,
      locale: 'auto',
      token: function (stripeToken: any) {       
        paymentstripe(stripeToken);
      },
    });
 
    const paymentstripe = (stripeToken: any) => {
     
         var payData= [];
    this.addons.forEach(element => {
      if(element.IsSelected){
        var data = new Checkout();
        data.addonId = element.index;
        
        data.addonPrice = element.AddonPrice;
        payData.push(data);
      }
    });

    var bookingData = new Checkout();
    bookingData.addonId = 7;
    bookingData.addonPrice = this.price;
    payData.push(bookingData);  

    var data = {
      stripeToken: stripeToken.id,
      paymentEmail:stripeToken.email,
      amount: this.calculatedTotal(),
      addons : payData
    }
    
    this.commonApi.getPromiseResponse({apiName: '/Booking/AddAddons/'+this.bookingId, methodType: 'post',parameterObject: data }).then((t)=>{      
       if(t.paymentstatus){
      this.route.navigate(["payment-success",t.transactionId]);
       }
       else{
        this.route.navigate(["payment-fail"]);
       }
      
     }).catch((t)=>{
       if(t?.message)
       {
         alert("Something went wrong! Please try again");
       }
     })


    };
 
    paymentHandler.open({
      name: 'Riding Waves',
      description: 'Booking for '+this.bookinfDetails.bookingName,
      amount: this.calculatedTotal() * 100,
      currency: 'EUR',
    });
  }

  invokeStripe() {
    if (!window.document.getElementById('stripe-script')) {
      const script = window.document.createElement('script');
      script.id = 'stripe-script';
      script.type = 'text/javascript';
      script.src = 'https://checkout.stripe.com/checkout.js';
      script.onload = () => {
        this.paymentHandler = (<any>window).StripeCheckout.configure({
          key: environment.stripeKey,
          locale: 'auto',
          currency: 'EUR',
          token: function (stripeToken: any) {
            
          },
        });
      };
 
      window.document.body.appendChild(script);
    }
  }

  redirectToStripe(){

    window.location.href = "https://buy.stripe.com/5kAbJS1DjfBM1Ak8ww"
  }

}
