import {
  Component,
  OnInit
} from '@angular/core';
import {
  ActivatedRoute
} from '@angular/router';
import {
  LocalStorageService
} from 'app/services/local-storage.service';
import {
  HttpClient
} from '@angular/common/http';
import { PutmethodService } from 'app/services/putmethod.service';

@Component({
  selector: 'app-payment-success',
  templateUrl: './payment-success.component.html',
  styleUrls: ['./payment-success.component.scss']
})
export class PaymentSuccessComponent implements OnInit {
  transactionId: any;s
  // Define a boolean variable to track whether the request has already been sent
  requestSent = false;
  constructor(private ac: ActivatedRoute, private localStorageService: LocalStorageService, private http: HttpClient,private putmethod: PutmethodService) {
    this.ac.params.subscribe(q => {
      this.transactionId = q["id"];
    })

  }

  ngOnInit(): void {
  }

  ngAfterViewInit(){
    this.render_api_call();
  }
  getData() {
    const storedData = localStorage.getItem('id1');
    const myParsedObject = JSON.parse(storedData);
    return myParsedObject
  }
  getData2() {
    const storedData = localStorage.getItem('id2');
    const myParsedObject = JSON.parse(storedData);
    return myParsedObject
  }


  render_api_call() {
    const payload = {
      "statusid": "2",
      "phoneid": this.getData().data,
      "nameid": this.getData2().data
    };
    fetch('https://python-backend-api-db-1.onrender.com/api/update_booking', {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(payload)
    })
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => console.log('Update successful:', data))
      .catch(error => {
        console.error('Error updating booking:', error);
        // Display a user-friendly error message

      });
    }
}