import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthHelper } from 'app/helpers/auth-helper';
import { CommonApiService } from 'app/services/common-api.service';
import { LocalStorageService } from 'app/services/local-storage.service';

@Component({
  selector: 'app-form-details',
  templateUrl: './form-details.component.html',
  styleUrls: ['./form-details.component.scss']
})
export class FormDetailsComponent implements OnInit {
  myFiles: any = [];
  myForm: FormGroup;
  selectedDate: any;


  constructor(private activatedRoute: ActivatedRoute, private commonApi:CommonApiService, private route : Router, private localStorageService: LocalStorageService ) {
    this.activatedRoute.queryParams.subscribe(q => {
      this.selectedDate = q['date'];
      
    })

  }


  ngOnInit(): void {

    this.myForm = new FormGroup({
      name: new FormControl('', [Validators.required, Validators.minLength(3)]),
      phone: new FormControl('', [Validators.required]),
      identifier: new FormControl('', [Validators.required]),
      date: new FormControl(this.selectedDate, [Validators.required]),
      email:new FormControl('',[Validators.email,Validators.required]),
      file: new FormControl('', [Validators.required])
    });

  }

  saveToLocalStorage(name_value,result,name_key) {
    const myObject = { name: name_value, data: result};
    this.localStorageService.setItem(name_key, myObject);
  }
  onFileChange(event) {
    var temproryArray = [];

    for (var i = 0; i < event.target.files.length; i++) {
      var file = event.target.files[i];
      var isSuccess = true;
      if(((file.size/1024)/1024) > 20){        
        isSuccess = false;
      }
      else{
        temproryArray.push(event.target.files[i]);
      }

      if(isSuccess){
      this.myFiles.push(event.target.files[i]);
      }
      else{
        alert("Filesize should be less the 20MB")   
        this.myForm.controls.file.reset();     
        event.target.value = null;
      }
    }
  }
  get f() {
    return this.myForm.controls;
  }


  submit() {
    const formData = new FormData();

    for (var i = 0; i < this.myFiles.length; i++) {
      formData.append("file", this.myFiles[i]);
    }
    this.saveToLocalStorage("x",this.myForm.value.phone,"id1")
    this.saveToLocalStorage("y",this.myForm.value.name,"id2")
    formData.append('name', this.myForm.value.name);
    formData.append('phone', this.myForm.value.phone);
    formData.append('identifier',this.myForm.value.identifier);
    formData.append('date',this.myForm.value.date);
    formData.append('email',this.myForm.value.email);
    formData.append('userId',"1");
    
    //save details in localStorage
    

    this.commonApi.getPromiseResponse({apiName: '/Booking/', methodType: 'post',parameterObject: formData }).then((t)=>{      
     this.route.navigate(["checkout",window.btoa(t.bookingId)]);
    }).catch((t)=>{
      if(t?.message)
      {
        alert("Something went wrong! Please try again");
      }
    })

    
  }
}
