import { AuthService } from "./../../services/auth.service";
import { Component, OnInit } from "@angular/core";
import { AlertService } from "app/services/alert.service";
import { DataService } from "app/services/data.service";
import { Router, ActivatedRoute } from "@angular/router";
import {
  FormBuilder,
  FormGroup,
  Validators,
  ReactiveFormsModule,
} from "@angular/forms";
import { first } from "rxjs/operators";

@Component({
  selector: "app-signup",
  templateUrl: "./signup.component.html",
  styleUrls: ["./signup.component.scss"],
})
export class SignupComponent implements OnInit {
  form: FormGroup;
  show: boolean = false;

  constructor(
    private accountService: DataService,
    private alertService: AlertService,
    private router: Router,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private AuthService: AuthService
  ) {}

  ngOnInit() {
    this.form = this.formBuilder.group({
        email: ["", Validators.required],
      password: ["", Validators.required],
    });
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.form.controls;
  }

  // click event function toggle
  password() {
    this.show = !this.show;
  }

  onSubmit() {
    

    // reset alerts on submit
    this.alertService.clear();

    // stop here if form is invalid
    if (this.form.invalid) {
      return;
    }

    let reqObj = {
      email: this.form.value.email,
      password: this.form.value.password,
    };

    this.AuthService.loginV2(reqObj)
      .then((s) => {
        this.router.navigate(["admin/dashboard"]);
      })
      .catch((error) => {
        if (error.message) {
          this.alertService.error("", error.message);
        }
      });
  }
  onRegister()
  {
    this.router.navigate(["signup"]);
  }
}
