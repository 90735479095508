import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import { CalendarEvent, CalendarView } from 'angular-calendar';
import { CommonApiService } from 'app/services/common-api.service';

declare let $: any;
@Component({
  selector: 'app-upcoming-events',
  templateUrl: './upcoming-events.component.html',
  styleUrls: ['./upcoming-events.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UpcomingEventsComponent implements OnInit,AfterViewInit {
  // selected: {startDate: Dayjs, endDate: Dayjs};

  view: CalendarView = CalendarView.Month;
  viewDate: Date = new Date();
  events: CalendarEvent[] =[];
  selectedDay: any;
  PrevEvent: any;
  showComponent: boolean;  

  constructor(private route: Router, private commonApi: CommonApiService,private detectChange : ChangeDetectorRef) { }
  
  // public selectedMoment = new Date();

  // isInvalidDate(date:any) {
  //   return date.getDay() === 0;
  // }

  ngOnInit(): void {
    this.commonApi.getPromiseResponse({ apiName: '/Booking/GetBookingByUserId/'+ 1, methodType: 'get', parameterObject: "" }).then((t) => {
      
      if (t.length > 0) {
        t.forEach(element => {
          this.events.push({
            start: new Date(new Date(element.bookingDate).toDateString()),
            end: new Date(new Date(element.bookingDate).toDateString()),
            title: element.bookingName
          })
        });
      }
      this.showComponent = true;
      this.detectChange.detectChanges();

      let table = $('#example').DataTable({        
        pageLength: 10
      });
      
    }).catch((t) => {
      if (t?.message) {
        alert("Something went wrong! Please try again 1");
      }
    })
  }


  ngAfterViewInit(): void {
   
  }

  redirectoDashboardPage(){
    this.route.navigate(["admin","dashboard"])
  }


  isDateSelected(e) {
    var returnData = false;
    this.events.forEach(element => {
      if (new Date(e.date).toDateString() == new Date(element.start).toDateString()) {
        returnData = true;
      }

    });
    return returnData;
  }

  GetDate(data) {
    
    var isDateSelected = this.isDateSelected(data.day);
    if (isDateSelected) {
      
    } else {
      if (this.PrevEvent?.day != data.day) {
        data.sourceEvent.currentTarget.classList.add("selected-cell");

        if (this.PrevEvent) {
          if (this.PrevEvent.sourceEvent.path[1].classList.contains("selected-cell")) {
            this.PrevEvent.sourceEvent.path[1].classList.remove("selected-cell")
          }
        }
        this.PrevEvent = data;
        this.selectedDay = new Date(data.day.date).toDateString()
        

      }
      
    }
  }

  redirectoNextPage() {
    this.route.navigate(["details"], { queryParams: { date: this.selectedDay } })
  }

}
