import { Component, OnInit } from '@angular/core';
import {TranslateModule,TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.scss']
})
export class LandingPageComponent implements OnInit {

  constructor(public translate: TranslateService) {
    // translate.addLangs(['en', 'es']);
    // translate.setDefaultLang('en');

    // const browserLang = translate.getBrowserLang();
    // translate.use(browserLang.match(/en|es/) ? browserLang : 'en');
  }

  ngOnInit(): void {

    // var merchant = {
    //   "DS_MERCHANT_AMOUNT": "145",
    //   "DS_MERCHANT_CURRENCY": "978",
    //   "DS_MERCHANT_MERCHANTCODE": "999008881",
    //   "DS_MERCHANT_MERCHANTURL": "http://www.prueba.com/urlNotificacion.php",
    //   "DS_MERCHANT_ORDER": "1446068581",
    //   "DS_MERCHANT_TERMINAL": "1",
    //   "DS_MERCHANT_TRANSACTIONTYPE": "0",
    //   "DS_MERCHANT_URLKO": "http://www.prueba.com/urlKO.php",
    //   "DS_MERCHANT_URLOK": "http://www.prueba.com/urlOK.php"
    // }

    // var jsonString = JSON.stringify(merchant);
    // console.log(jsonString);

    // var base64string =  this.b64EncodeUnicode(jsonString);
    // console.log(base64string);
  }

  b64EncodeUnicode(str) {
    return btoa(unescape(encodeURIComponent(str)));
  };
 
}
