import { Injectable, OnDestroy } from '@angular/core';
import { Observable, BehaviorSubject, of, Subscription, Subject } from 'rxjs';
import { Router } from '@angular/router';
import * as JWT from 'jwt-decode';
import { AlertService } from './alert.service';
import { AuthHelper } from 'app/helpers/auth-helper';
import { CommonApiService } from './common-api.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService implements OnDestroy {
  
  isAuthenticated: boolean;
  private isLoginin = new Subject<any>();
  
  constructor(
    private router: Router,
    private commonApiService : CommonApiService
  ) {
  }

  public loginSubscription(): Observable<any> {
    return this.isLoginin.asObservable();
  }

  loginV2(params: any) {
    return new Promise((resolve, reject) => {

      this.commonApiService.getPromiseResponse({ apiName: '/login', methodType: 'post',
       parameterObject: params }).then(result => {
        if (result.id) {
          localStorage.setItem('token', result.token);
          localStorage.setItem('id', result.id);
          localStorage.setItem('email', result.email);
          localStorage.setItem('userType',result.userType);
          if (params.is_remember) {
            localStorage.setItem('is_remember', params.is_remember);
          } else {
            localStorage.removeItem('is_remember');
          }
          this.isAuthenticated = true;
          this.ValidateJWT()
          this.isLoginin.next(true);
       }
        resolve(result);
      }).catch(error => {
        reject(error);
      });
      
    });
  }

  ValidateJWT()
  {
    try {
      if(localStorage.getItem('token'))
      {
        let token = JSON.parse(JSON.stringify(JWT.default(localStorage.getItem('token') || '')));
        if(token && token.UserData)
        {
          var userData = JSON.parse(token.UserData)
          AuthHelper.UserDetails.UserId = userData.UserId;
          AuthHelper.UserDetails.Email = userData.EmailAddress;
          AuthHelper.UserDetails.UserType = userData.UserType;
          AuthHelper.UserDetails.Name = userData.FirstName + ' ' + (userData?.LastName ? userData?.LastName : '');
        }
        const date = new Date();
        const timeStamp = date.getTime();
        const timeout = token.exp * 1000 - timeStamp;
          if (timeout < 0) {
            this.logout();
          }
      }
      
    } catch (e){
      this.logout();
    }
  }

  HandleAuthRouteAccess()
  {
    this.ValidateJWT();
    // this.router.navigate(['dashboard']);
  }

  logout() {
    this.isLoginin.next(false);
    localStorage.clear();
    this.isAuthenticated = false
    AuthHelper.UserDetails.Email = null
    AuthHelper.UserDetails.UserId = null
    AuthHelper.UserDetails.UserType = null
    AuthHelper.UserDetails.Name = null
    this.router.navigate(['signin'], {
      queryParams: {},
    });
  }

  getUserByToken(): Observable<any> {
    const auth = localStorage.getItem('token');
    if (!auth) {
      return of(undefined);
    }
    this.isAuthenticated = true;
    this.ValidateJWT()
    return of();
  }
  
  ngOnDestroy() {
    
  }
}
