// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  //apiUrl: "https://localhost:5001/api",
  apiUrl:"https://rwbackend.azurewebsites.net/api",
  //stripeKey:"pk_test_51LIxqdGED494fLz3Z7IBJ5vUHdU3C7NpSkMIMr0vMs2gz2n52WLwlwwxyom8pkJ9x3NOCt4VMFx19o1vgTFNKfiO0045sWIj1M"
  stripeKey:"pk_live_51LIxqdGED494fLz34EQblAsaJHWIltIPFzGTaThleMKhp8W7dUQ6VviKJ8C4m0mbjWowr56KOQeR7PYy1TjBVqFH00maeql32p"
};
