import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import {RouterModule} from '@angular/router';

import { LandingComponent } from './landing/landing.component';
import { ProfileComponent } from './profile/profile.component';
import { SignupComponent } from './signup/signup.component';
import { NewsignupComponent } from './newsignup/newsignup.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';

import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { FormDetailsComponent } from './form-details/form-details.component';

import {TranslateModule, TranslateLoader} from '@ngx-translate/core'; //translate module for the several languages
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {HttpClientModule, HttpClient} from '@angular/common/http';
import { PaymentSuccessComponent } from './payment-success/payment-success.component';
import { PaymentFailComponent } from './payment-fail/payment-fail.component';

export function HttpLoaderFactory(httpClient: HttpClient) {
    return new TranslateHttpLoader(httpClient, '/assets/i18n/','.json');
  }
  

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        FormsModule,
        NgbModule,
        ReactiveFormsModule,
        HttpClientModule,
        NgxDaterangepickerMd.forRoot(),
        CalendarModule.forRoot({
            provide: DateAdapter,
            useFactory: adapterFactory,
        }),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        
    ],
    declarations: [
        LandingComponent,
        SignupComponent,
        ProfileComponent,
        NewsignupComponent,
        DashboardComponent,
        FormDetailsComponent,
        PaymentSuccessComponent,
        PaymentFailComponent
    ],
    schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class ExamplesModule { }
