import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class PutmethodService {
  constructor(private http: HttpClient) {}

  updateData(data: any): Observable<any> {
    const url = 'https://python-backend-api-db.onrender.com/update_booking';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    const options = { headers: headers };

    return this.http.put(url, data, options);
  }
}
