import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';

import { User } from '../models/user';
import { environment } from '../../environments/environment';


@Injectable({
  providedIn: 'root'
})


export class DataService {
  private userSubject: BehaviorSubject<User>;
  public user: Observable<User>;

  constructor(
    private router: Router,
    private http: HttpClient
  ) {
    this.userSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('user')));
    this.user = this.userSubject.asObservable();
  }

  public get userValue(): User {
    return this.userSubject.value;
  }

  login(username, password) {
    return this.http.post<User>(`${environment.apiUrl}/users/authenticate`, { username, password })
      .pipe(map(user => {
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        localStorage.setItem('user', JSON.stringify(user));
        this.userSubject.next(user);
        return user;
      }));
  }

  logout() {
    // remove user from local storage and set current user to null
    localStorage.removeItem('user');
    this.userSubject.next(null);
    this.router.navigate(['/account/login']);
  }


  //set parameters for the API to do several orders
  register(user: User) {
    return this.http.post(`${environment.apiUrl}/users`, user);
}

getById(id: string) {
  return this.http.get<User>(`${environment.apiUrl}/users/${id}`);
}

update(id, params) {
  return this.http.put(`${environment.apiUrl}/users/${id}`, params)
      .pipe(map(x => {
          // update stored user if the logged in user updated their own record
          if (id == this.userValue.id) {
              // update local storage
              const user = { ...this.userValue, ...params };
              localStorage.setItem('user', JSON.stringify(user));

              // publish updated user to subscribers
              this.userSubject.next(user);
          }
          return x;
      }));
}



  // export class DataService {

  // readonly APIUrl ="https://localhost:5001/api"


  // constructor(private http:HttpClient) { }


  // getUserList():Observable<any[]>{
  //   return this.http.get<any>(this.APIUrl+'/users')
  // }

  // addUser(val:any){
  //   return this.http.post(this.APIUrl+'/users',val)
  // }

  // updateUser(val:any){
  //   return this.http.put(this.APIUrl+'/users',val)
  // }

  // deleteUser(val:any){
  //   return this.http.delete(this.APIUrl+'/users'+ val)
  // }


}